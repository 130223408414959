import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import ExploreAll from './Pages/ExploreAll';
import ExploreAllPage2 from './Pages/ExlporeAllPage2';
import ExploreAllPage3 from './Pages/ExploreAllPage3';
import PaperStatus from './Pages/PaperStatus.js';
import Publish from './Pages/Publish.js';
import Conferences from './Pages/Conferences.js';
import Disclaimer from './Pages/Disclaimer.js';
import PrivacyPolicy from './Pages/PrivacyPolicy.js';
import TermsAndConditions from './Pages/TermsAndConditions.js';


import ABriefReviewOfTheHistoryOfComputationalIntelligence from './Components/Papers/ABriefReviewOfTheHistoryOfComputationalIntelligence.js';
import AdvancementsAndFutureDirectionsInMolecularDynamics from './Components/Papers/AdvancementsAndFutureDirectionsInMolecularDynamics.js';
import CloudBurstingWithGlideinwmsMeansToSatisfyEveryIncreasingNeededForScientificWorkflow from './Components/Papers/CloudBurstingWithGlideinwmsMeansToSatisfyEveryIncreasingNeededForScientificWorkflow.js';
import ComputationalAndNumericalMethodsWithoutRelaxingtheConstraintsOnTheNumberofNodesInTheGraph from './Components/Papers/ComputationalAndNumericalMethodsWithoutRelaxingtheConstraintsOnTheNumberofNodesInTheGraph.js';
import DataScienceAndComputationalBiology from './Components/Papers/DataScienceAndComputationalBiology.js';
import EnhancingFutureLinkPredictionInQuantumComputSemanticNetworkThroughLLM from './Components/Papers/EnhancingFutureLinkPredictionInQuantumComputSemanticNetworkThroughLLM.js';
import AnExplorationOfTheQuantumFrontierInDataAnalyticsAndComputationalIntelligence from './Components/Papers/AnExplorationOfTheQuantumFrontierInDataAnalyticsAndComputationalIntelligence.js';
import TheRoleOfQuantumComputingInAdvancingCrossDisciplinaryInformatic from './Components/Papers/TheRoleOfQuantumComputingInAdvancingCrossDisciplinaryInformatic.js';
import EnvisioningAComputingContinuumForScience from './Components/Papers/EnvisioningAComputingContinuumForScience.js';
import IntelligentNaturalLanguageSearchforRandDDocuments from './Components/Papers/IntelligentNaturalLanguageSearchforRandDDocuments.js';
import BYODResearchPaper from './Components/Papers/BYOD_Research_Paper.js';
import OnlineInspectionofPackedCases from './Components/Papers/OnlineInspectionofPackedCases.js';
import A24120009 from './Components/Papers/A24120009.js';
import A24120012 from './Components/Papers/A24120012.js';
import A24120022 from './Components/Papers/A24120022.js';
import A24120003 from './Components/Papers/A24120003.js';
import A24120002 from './Components/Papers/A24120002.js';
import A24120036 from './Components/Papers/A24120036.js';
import A24120038 from './Components/Papers/A24120038.js';
import A24120034 from './Components/Papers/A24120034.js';
import A24120028 from './Components/Papers/A24120028.js';
import A24120025 from './Components/Papers/A24120025.js';
import A24120027 from './Components/Papers/A24120027.js';
import A24120035 from './Components/Papers/A24120035.js';
import A24120037 from './Components/Papers/A24120037.js';
// import pansp from './Pages/privacyandpolicy.html';


function App() {
  return (
    <Routes>

      <Route path='/publish' element={<Publish />} />
      <Route path='/home/publish' element={<Publish />} />

      <Route path='/disclaimer' element={<Disclaimer />} />
      <Route path='/home/disclaimer' element={<Disclaimer />} />

      <Route path='/privacyandpolicy' element={<PrivacyPolicy />} />
      <Route path='/home/privacyandpolicy' element={<PrivacyPolicy />} />
      
      {/* <Route path='/privacyandpolicy' to = 'pansp'/>
      <Route path='/home/privacyandpolicy'/> */}

      <Route path='/termsandconditions' element={<TermsAndConditions />} />
      <Route path='/home/termsandconditions' element={<TermsAndConditions />} />

      <Route path='/conferences' element={<Conferences />} />
      <Route path='/home/conferences' element={<Conferences />} />

      <Route path='/primeph' element={<Home />} />
      <Route path='/home/primeph' element={<Home />} />


      <Route path='/paper-status' element={<PaperStatus />} />
      <Route path='/home/paper-status' element={<PaperStatus />} />


      <Route path='/' element={<Home />} />
      <Route path='/home' element={<Navigate to="/" />} />
      <Route path='/explore-all' element={<ExploreAll />} />
      <Route path='/explore-all/page2' element={<ExploreAllPage2 />} />
      <Route path='/explore-all/page3' element={<ExploreAllPage3 />} />
      <Route path='/home/a-brief-review-of-the-history-of-computational-intelligence' element={<ABriefReviewOfTheHistoryOfComputationalIntelligence />} />
      <Route path='/a-brief-review-of-the-history-of-computational-intelligence' element={<ABriefReviewOfTheHistoryOfComputationalIntelligence />} />
      <Route path='/home/advancements-and-future-directions-in-molecular-dynamics' element={<AdvancementsAndFutureDirectionsInMolecularDynamics />} />
      <Route path='/advancements-and-future-directions-in-molecular-dynamics' element={<AdvancementsAndFutureDirectionsInMolecularDynamics />} />
      <Route path='/cloud-bursting-with-glideinwms-means-to-satisfy-every-increasing-needed-for-scientific-workflow' element={<CloudBurstingWithGlideinwmsMeansToSatisfyEveryIncreasingNeededForScientificWorkflow />} />
      <Route path='/home/cloud-bursting-with-glideinwms-means-to-satisfy-every-increasing-needed-for-scientific-workflow' element={<CloudBurstingWithGlideinwmsMeansToSatisfyEveryIncreasingNeededForScientificWorkflow />} />
      <Route path='/computational-and-numerical-methods-for-combinatorial-geometric-series-and-its-applications' element={<ComputationalAndNumericalMethodsWithoutRelaxingtheConstraintsOnTheNumberofNodesInTheGraph />} />
      <Route path='/home/computational-and-numerical-methods-for-combinatorial-geometric-series-and-its-applications' element={<ComputationalAndNumericalMethodsWithoutRelaxingtheConstraintsOnTheNumberofNodesInTheGraph />} />
      <Route path='/data-science-and-computational-biology' element={<DataScienceAndComputationalBiology />} />
      <Route path='/home/data-science-and-computational-biology' element={<DataScienceAndComputationalBiology />} />

      <Route path='/Intelligent-Natural-Language-Search-for-R&D-Documents' element={<IntelligentNaturalLanguageSearchforRandDDocuments />} />
      <Route path='/home/Intelligent-Natural-Language-Search-for-R&D-Documents' element={<IntelligentNaturalLanguageSearchforRandDDocuments />} />

      <Route path='/real-time-queue-detection-and-management-system-using-yolo-object-detection' element={<A24120009 />} />
      <Route path='/home/real-time-queue-detection-and-management-system-using-yolo-object-detection' element={<A24120009 />} />

      <Route path='/2412-0012' element={<A24120012 />} />
      <Route path='/home/2412-0012' element={<A24120012 />} />

      <Route path='/2412-0022' element={<A24120022 />} />
      <Route path='/home/2412-0022' element={<A24120022 />} />

      <Route path='/2412-0003' element={<A24120003 />} />
      <Route path='/home/2412-0003' element={<A24120003 />} />

      <Route path='/2412-0002' element={<A24120002 />} />
      <Route path='/home/2412-0002' element={<A24120002 />} />

      <Route path='/2412-0036' element={<A24120036 />} />
      <Route path='/home/2412-0036' element={<A24120036 />} />

      <Route path='/2412-0038' element={<A24120038 />} />
      <Route path='/home/2412-0038' element={<A24120038 />} />

      <Route path='/2412-0034' element={<A24120034 />} />
      <Route path='/home/2412-0034' element={<A24120034 />} />

      <Route path='/2412-0028' element={<A24120028 />} />
      <Route path='/home/2412-0028' element={<A24120028 />} />

      <Route path='/2412-0025' element={<A24120025 />} />
      <Route path='/home/2412-0025' element={<A24120025 />} />

      <Route path='/2412-0027' element={<A24120027 />} />
      <Route path='/home/2412-0027' element={<A24120027 />} />

      <Route path='/2412-0035' element={<A24120035 />} />
      <Route path='/home/2412-0035' element={<A24120035 />} />

      <Route path='/2412-0037' element={<A24120037 />} />
      <Route path='/home/2412-0037' element={<A24120037 />} />

      <Route path='/enhancing-future-link-prediction-in-quantum-computing-semantic-networks-through-llm-initiated-node-features' element={<EnhancingFutureLinkPredictionInQuantumComputSemanticNetworkThroughLLM />} />
      <Route path='/home/enhancing-future-link-prediction-in-quantum-computing-semantic-networks-through-llm-initiated-node-features' element={<EnhancingFutureLinkPredictionInQuantumComputSemanticNetworkThroughLLM />} />

      <Route path='/an-exploration-of-the-quantum-frontier-in-data-analytics-and-computational-intelligence' element={<AnExplorationOfTheQuantumFrontierInDataAnalyticsAndComputationalIntelligence />} />
      <Route path='/home/an-exploration-of-the-quantum-frontier-in-data-analytics-and-computational-intelligence' element={<AnExplorationOfTheQuantumFrontierInDataAnalyticsAndComputationalIntelligence />} />

      <Route path='/the-role-of-quantum-computing-in-advancing-cross-disciplinary-informatics' element={<TheRoleOfQuantumComputingInAdvancingCrossDisciplinaryInformatic />} />
      <Route path='/home/the-role-of-quantum-computing-in-advancing-cross-disciplinary-informatics' element={<TheRoleOfQuantumComputingInAdvancingCrossDisciplinaryInformatic />} />

      <Route path='/body-research-paper' element={<BYODResearchPaper />} />
      <Route path='/home/body-research-paper' element={<BYODResearchPaper />} />

      <Route path='/online-inspection-of-packed-case' element={<OnlineInspectionofPackedCases />} />
      <Route path='/home/online-inspection-of-packed-case' element={<OnlineInspectionofPackedCases />} />

      <Route path='/envisioning-a-computing-continuum-for-science' element={<EnvisioningAComputingContinuumForScience />} />
      <Route path='/home/envisioning-a-computing-continuum-for-science' element={<EnvisioningAComputingContinuumForScience />} />
      <Route path="*" element={<Navigate to="/" />} />

    </Routes>
  );
}

export default App;
