import React, { useState } from "react";
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/aos/aos.css";
import "../assets/vendor/glightbox/css/glightbox.min.css";
import "../assets/vendor/swiper/swiper-bundle.min.css";
import "../assets/css/main.css";
import "../assets/img/favicon.png"; // For favicon
import avatar1 from "../assets/img/avatar1.webp";
import avatar2 from "../assets/img/avatar2.webp";
import avatar3 from "../assets/img/avatar3.webp";
import avatar4 from "../assets/img/avatar4.webp";
import avatar5 from "../assets/img/avatar5.webp";
import illustration_1 from "../assets/img/illustration_1.png";
import features_illustration_1 from "../assets/img/features_illustration_1.svg";
import features_illustration_2 from "../assets/img/features_illustration_2.svg";
import features_illustration_3 from "../assets/img/features_illustration_3.svg";
import client1 from '../assets/img/clients/client1.png';
import client2 from '../assets/img/clients/client2.jpg';
import client3 from '../assets/img/clients/client3.png';
import client4 from '../assets/img/clients/client4.png';
import client5 from '../assets/img/clients/client5.png';
import client6 from '../assets/img/clients/client6.png';
import client7 from '../assets/img/clients/client7.png';
import client8 from '../assets/img/clients/client8.jpg';

import about5 from "../assets/img/about5.svg";
import AOS from "aos";
import "swiper/swiper-bundle.css";
import Swiper from 'swiper';
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Components/Navbar";
import data from '../Data/Data'


function Home() {

    useEffect(() => {

        function toggleScrolled() {
            const selectBody = document.querySelector("body");
            const selectHeader = document.querySelector("#header");
            if (
                !selectHeader ||
                (!selectHeader.classList.contains("scroll-up-sticky") &&
                    !selectHeader.classList.contains("sticky-top") &&
                    !selectHeader.classList.contains("fixed-top"))
            )
                return;
            window.scrollY > 100
                ? selectBody.classList.add("scrolled")
                : selectBody.classList.remove("scrolled");
        }

        document.addEventListener("scroll", toggleScrolled);
        window.addEventListener("load", toggleScrolled);

        /**
         * Mobile nav toggle
         */
        const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

        function mobileNavToogle() {
            document.querySelector("body").classList.toggle("mobile-nav-active");
            mobileNavToggleBtn.classList.toggle("bi-list");
            mobileNavToggleBtn.classList.toggle("bi-x");
        }
        if (mobileNavToggleBtn) {
            mobileNavToggleBtn.addEventListener("click", mobileNavToogle);
        }

        /**
         * Hide mobile nav on same-page/hash links
         */
        document.querySelectorAll("#navmenu a").forEach((navmenu) => {
            navmenu.addEventListener("click", () => {
                if (document.querySelector(".mobile-nav-active")) {
                    mobileNavToogle();
                }
            });
        });

        /**
         * Toggle mobile nav dropdowns
         */
        document
            .querySelectorAll(".navmenu .toggle-dropdown")
            .forEach((navmenu) => {
                navmenu.addEventListener("click", function (e) {
                    e.preventDefault();
                    this.parentNode.classList.toggle("active");
                    this.parentNode.nextElementSibling.classList.toggle(
                        "dropdown-active"
                    );
                    e.stopImmediatePropagation();
                });
            });

        /**
         * Scroll top button
         */
        let scrollTop = document.querySelector(".scroll-top");

        function toggleScrollTop() {
            if (scrollTop) {
                window.scrollY > 100
                    ? scrollTop.classList.add("active")
                    : scrollTop.classList.remove("active");
            }
        }
        scrollTop.addEventListener("click", (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });

        window.addEventListener("load", toggleScrollTop);
        document.addEventListener("scroll", toggleScrollTop);

        /**
         * Animation on scroll function and init
         */
        function aosInit() {
            AOS.init({
                duration: 600,
                easing: "ease-in-out",
                once: true,
                mirror: false,
            });
        }
        window.addEventListener("load", aosInit);

        /**
         * Frequently Asked Questions Toggle
         */
        document
            .querySelectorAll(".faq-item h3, .faq-item .faq-toggle")
            .forEach((faqItem) => {
                faqItem.addEventListener("click", () => {
                    faqItem.parentNode.classList.toggle("faq-active");
                });
            });

        /**
         * Correct scrolling position upon page load for URLs containing hash links.
         */
        window.addEventListener("load", function (e) {
            if (window.location.hash) {
                if (document.querySelector(window.location.hash)) {
                    setTimeout(() => {
                        let section = document.querySelector(window.location.hash);
                        let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
                        window.scrollTo({
                            top: section.offsetTop - parseInt(scrollMarginTop),
                            behavior: "smooth",
                        });
                    }, 100);
                }
            }
        });

        /**
         * Navmenu Scrollspy
         */
        let navmenulinks = document.querySelectorAll(".navmenu a");

        function navmenuScrollspy() {
            navmenulinks.forEach((navmenulink) => {
                if (!navmenulink.hash) return;
                let section = document.querySelector(navmenulink.hash);
                if (!section) return;
                let position = window.scrollY + 200;
                if (
                    position >= section.offsetTop &&
                    position <= section.offsetTop + section.offsetHeight
                ) {
                    document
                        .querySelectorAll(".navmenu a.active")
                        .forEach((link) => link.classList.remove("active"));
                    navmenulink.classList.add("active");
                } else {
                    navmenulink.classList.remove("active");
                }
            });
        }
        window.addEventListener("load", navmenuScrollspy);
        document.addEventListener("scroll", navmenuScrollspy);

        const initSwiper = () => {
            document.querySelectorAll(".init-swiper").forEach((swiperElement) => {
                const configElement = swiperElement.querySelector(".swiper-config");
                if (configElement) {
                    const config = JSON.parse(configElement.innerHTML.trim());
                    new Swiper(swiperElement, config);
                } else {
                    console.error("Swiper configuration not found.");
                }
            });
        }
        initSwiper();

        const scriptURL = 'https://script.google.com/macros/s/AKfycbw5vdc40D4uX1QcofQv8rzltuBpKT8SsT8FH1SgPXgmzq_5XL6y0yFB1XMw2aW8aY8/exec'; // Replace with your Web App URL
        const form = document.forms['contactForm'];

        form.addEventListener('submit', (e) => {
            e.preventDefault();
            fetch(scriptURL, {
                method: 'POST',
                body: new FormData(form),
                mode: 'no-cors', // Add this to avoid CORS errors
            })
                .then(() => {
                    toast.success('Thanks for Contacting us! We will get back to you soon.');
                    form.reset();
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });


    }, []);

    const [search, setSearch] = useState("");

    // When the user clicks on the button, scroll to the top of the document

    const dataPublication = data.filter((data) => {
        return search.toLowerCase() === '' ? data : data.journalTitle.toLowerCase().includes(search)
    }).map((data) => {

        return (
            <div className='publication-card' key={data._id}>
                <p>
                    <span className='authors'>{data.authorOneFname}{data.authorTwoFname ? ", " : ""}{data.authorTwoFname}{data.authorThreeFname ? ", " : ""}{data.authorThreeFname}{data.authorFourFname ? ", " : ""}{data.authorFourFname}{data.authorFiveFname ? ", " : ""}{data.authorFiveFname},{" "}</span>
                    <span className='journalTitle'>
                        {/* <a href={data.journalLink}>{data.journalTitle}{" "}</a> */}
                        <Link className='publication-card-link' to={data.journalLink}>
                            {data.journalTitle},{" "}
                        </Link>
                    </span>
                    <span className='journalPublisher'>{data.journalPublisher},{" "}</span>
                    <span className='publicationLanguage'>{data.publicationLanguage},{" "}</span>
                    <span className='institution'>{data.institution},{" "}</span>
                    <span className='website'>
                        <Link className='publication-card-link' to={data.website}>
                            {data.website},{" "}
                        </Link>
                    </span>
                    <span className='state'>{data.state},{" "}</span>
                    <span className='preparedDate'>{data.preparedDate},{" "}</span>
                    <span className='openPaper'>
                        [<Link to={data.openPaper} target="_blank">Open pdf</Link>]
                    </span>
                    <span>{" "}</span>
                    <span className='downloadPaper'>
                        [<Link to={data.downloadPaper}>Download</Link>]
                    </span>
                    <span>{" "}</span>
                    {data.code === "" ? null : <span className='code'>[<Link to={data.code} target="_blank">Code</Link>]</span>}</p>
                <span>AuthorID:{data.authorId}</span> <br />
                <span>ISSN No.: {data.issnId}</span>
                <hr />
            </div>
        )
    })


    return (
        <body class="index-page">
            <Navbar />
            <main className="main">
                <ToastContainer />
                <section id="hero" className="hero section">
                    <div className="container" data-aos="fade-up" data-aos-delay={100}>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div
                                    className="hero-content"
                                    data-aos="fade-up"
                                    data-aos-delay={200}
                                >
                                    {/* <div className="company-badge mb-4">
                                        <i className="bi bi-gear-fill me-2" />
                                        Working for your success
                                    </div> */}
                                    <h1 className="mb-4">
                                        Modern Publication <br />
                                        Made Simple <br />
                                        <span className="accent-text">Prime Publication Hub</span>
                                    </h1>
                                    <p className="mb-4 mb-md-5">
                                        Empowering authors with a seamless platform to publish their
                                        journals and reach a global audience. Experience effortless
                                        publishing with tools designed for your success.
                                    </p>
                                    <div className="hero-buttons">
                                        <Link to="/publish"><a href="/" className="btn btn-primary me-0 me-sm-2 mx-1">Publish</a></Link>
                                        <Link to='/explore-all'><a href="/" className="btn btn-link mt-2 mt-sm-0 glightbox">
                                            <i className="bi bi-arrow-up-right-circle me-1" />Explore all
                                        </a></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="hero-image"
                                    data-aos="zoom-out"
                                    data-aos-delay={300}
                                >
                                    <img
                                        src={illustration_1}
                                        alt="Hero icon"
                                        className="img-fluid"
                                    />
                                    <div className="customers-badge">
                                        <div className="customer-avatars">
                                            <img src={avatar1} alt="Customer 1" className="avatar" />
                                            <img src={avatar2} alt="Customer 2" className="avatar" />
                                            <img src={avatar3} alt="Customer 3" className="avatar" />
                                            <img src={avatar4} alt="Customer 4" className="avatar" />
                                            <img src={avatar5} alt="Customer 5" className="avatar" />
                                            <span className="avatar more">12+</span>
                                        </div>
                                        <p className="mb-0 mt-2">
                                            Over 1200+ satisfied authors trust us for seamless
                                            publishing and unparalleled support.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <style
                            dangerouslySetInnerHTML={{
                                __html:
                                    "\n          .recent-publications-p {\n            padding-left: 2rem;\n          }\n\n          .recent-publications-h2 {\n            padding-left: 2rem;\n          }\n        ",
                            }}
                        />
                        <div
                            className="row stats-row gy-4 "
                            data-aos="fade-up"
                            data-aos-delay={500}
                        >

                            <h2 className="recent-publications-h2 mb-2">Recent Publication</h2>
                            <input type="text" className="form-control" placeholder="Search by title" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => { setSearch(e.target.value) }} />
                            <div className="recent-publications-p">
                                {dataPublication}
                            </div>
                        </div>
                        <div
                            className="row stats-row gy-4 mt-5"
                            data-aos="fade-up"
                            data-aos-delay={500}
                        >
                            <div className="col-lg-4 col-md-6">
                                <div className="stat-item">
                                    <div className="stat-icon">
                                        <i className="bi bi-collection" />
                                    </div>
                                    <div className="stat-content">
                                        <h4>Extensive Library Overview</h4>
                                        <p>
                                            <strong>100+ Journals, 500+ Articles:</strong> Discover
                                            diverse research, reviews, and case studies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="stat-item">
                                    <div className="stat-icon">
                                        <i className="bi bi-rocket-takeoff" />
                                    </div>
                                    <div className="stat-content">
                                        <h4>Fast Processing</h4>
                                        <p>
                                            <strong>Quick Publishing:</strong> Ready in under 2 hours.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="stat-item">
                                    <div className="stat-icon">
                                        <i className="bi bi-card-text" />
                                    </div>
                                    <div className="stat-content">
                                        <h4>Open Access Features</h4>
                                        <p className="mb-0">
                                            Promoting free or low-cost publishing for a wider
                                            audience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="stat-item">
                                    <div className="stat-icon">
                                        <i className="bi bi-file-earmark-arrow-down" />
                                    </div>
                                    <div className="stat-content">
                                        <h4>Reader Tools</h4>
                                        <p className="mb-0">
                                            Search and filter by topics, journal name, or author.
                                            Download articles in PDF format.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="stat-item">
                                    <div className="stat-icon">
                                        <i className="bi bi-star" />
                                    </div>
                                    <div className="stat-content">
                                        <h4>Testimonial Section</h4>
                                        <p className="mb-0">
                                            Read feedback from authors and readers to build trust.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="stat-item">
                                    <div className="stat-icon">
                                        <i className="bi bi-person-gear" />
                                    </div>
                                    <div className="stat-content">
                                        <h4>Author Services</h4>
                                        <p className="mb-0">
                                            We provide abstract writing, formatting assistance, and
                                            data analysis for submissions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="about" className="about section">
                    <div className="container" data-aos="fade-up" data-aos-delay={100}>
                        <div className="row gy-4 align-items-center justify-content-between">
                            <div className="col-xl-5" data-aos="fade-up" data-aos-delay={200}>
                                <span className="about-meta">MORE ABOUT US</span>
                                <h2 className="about-title">
                                    <p>Welcome to College Resource</p>
                                </h2>
                                <p className="about-description">
                                    The platform designed to empower authors to publish and share
                                    their creative work with ease. Whether you're an experienced
                                    author or just starting, we provide the tools to make your
                                    vision a reality.
                                </p>
                                <div className="row feature-list-wrapper">
                                    <div className="col-md-12">
                                        <ul className="feature-list">
                                            <li>
                                                <i className="bi bi-check-circle-fill" />
                                                Provides tools to transform your vision into reality.
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" />
                                                Offers intuitive editing features and seamless
                                                publishing options.
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" />
                                                Ensures a smooth journey from draft to publication.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-12">
                                        <ul className="feature-list">
                                            <li>
                                                <i className="bi bi-check-circle-fill" />
                                                To create a space where stories come to life.
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" />
                                                To foster connections between authors and their
                                                audiences.
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" />
                                                Encourages a growing community that celebrates
                                                creativity and innovation.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6" data-aos="fade-up" data-aos-delay={300}>
                                <div className="image-wrapper">
                                    <div
                                        className="images position-relative"
                                        data-aos="zoom-out"
                                        data-aos-delay={400}
                                    >
                                        <img
                                            src={about5}
                                            alt="Business Meeting"
                                            className="img-fluid main-image rounded-4"
                                        />
                                    </div>
                                    <div className="experience-badge floating">
                                        <h3>
                                            15+ <span>Years</span>
                                        </h3>
                                        <p>Of Experience in Publication Service</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="features" className="features section">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Guidelines</h2>
                        <p>Guidelines for Publishing a Research Paper</p>
                    </div>
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <ul
                                className="nav nav-tabs"
                                data-aos="fade-up"
                                data-aos-delay={100}
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active show"
                                        data-bs-toggle="tab"
                                        data-bs-target="#features-tab-1"
                                        href="/"
                                    >
                                        <h4>Paper</h4>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="/"

                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        data-bs-target="#features-tab-2"
                                    >
                                        <h4>Code</h4>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/"

                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        data-bs-target="#features-tab-3"
                                    >
                                        <h4>Other</h4>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="tab-content"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="tab-pane fade active show" id="features-tab-1">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Guidelines for Publishing a Research Paper</h3>
                                        <p className="fst-italic">
                                            1. Select a Suitable Journal or Conference
                                        </p>
                                        <p className="fst-italic">2. Originality and Plagiarism</p>
                                        <p className="fst-italic">3. Unique and Related Paper</p>
                                        <p className="fst-italic">
                                            4. Follow the Formatting Guidelines
                                        </p>
                                        <p className="fst-italic">5. Abstract and Keywords</p>
                                        <p className="fst-italic">6. Figures and Graphs</p>
                                        <p className="fst-italic">
                                            7. Proofreading and Peer Review
                                        </p>
                                        <p className="fst-italic">8. Ethical Considerations</p>
                                        <p className="fst-italic">9. Datasets</p>
                                        <p className="fst-italic">10. Supplementary Results</p>
                                        <p className="fst-italic">11. Submission Process</p>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img
                                            src={features_illustration_1}
                                            alt="loading..."
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="features-tab-2">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Guidelines for Code Publication</h3>
                                        <p className="fst-italic">1. Choose a Hosting Platform</p>
                                        <p className="fst-italic">2. Documentation</p>
                                        <p className="fst-italic">3. ReadMe or Documentation</p>
                                        <p className="fst-italic">4. Interactive Tools</p>
                                        <p className="fst-italic">5. Licensing</p>
                                        <p className="fst-italic">6. Version Control</p>
                                        <p className="fst-italic">7. Code Quality</p>
                                        <p className="fst-italic">8. Testing</p>
                                        <p className="fst-italic">9. Cite the Code</p>
                                        <p className="fst-italic">10. References to Related Work</p>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img
                                            src={features_illustration_2}
                                            alt="loading..."
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="features-tab-3">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Other Guidelines </h3>
                                        <p className="fst-italic">1. Highlight Contributions</p>
                                        <p className="fst-italic">2. Clear Methodology</p>
                                        <p className="fst-italic">3. ReadMe or Documentation</p>
                                        <p className="fst-italic">4. Data Transparency</p>
                                        <p className="fst-italic">5. Grammar and Language Checks</p>
                                        <p className="fst-italic">6. Security</p>
                                        <p className="fst-italic">7. Accessibility</p>
                                        <p className="fst-italic">8. Code Dependencies</p>
                                        <p className="fst-italic">
                                            9. API Endpoints (if applicable)
                                        </p>
                                        <p className="fst-italic">10. Archiving</p>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img
                                            src={features_illustration_3}
                                            alt="loading..."
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="features-cards" className="features-cards section">
                    <div className="container">
                        <div className="row gy-4">
                            <div
                                className="col-xl-3 col-md-6"
                                data-aos="zoom-in"
                                data-aos-delay={200}
                            >
                                <div className="feature-box blue">
                                    <i className="bi bi-patch-check" />
                                    <h4>Content Quality</h4>
                                    <p>Ensure the report is unique, informative, and readable.</p>
                                </div>
                            </div>
                            <div
                                className="col-xl-3 col-md-6"
                                data-aos="zoom-in"
                                data-aos-delay={100}
                            >
                                <div className="feature-box orange">
                                    <i className="bi bi-award" />
                                    <h4>Ethics Approval</h4>
                                    <p>
                                        Ensure ethical compliance, especially if the report involves
                                        human or animal subjects.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="col-xl-3 col-md-6"
                                data-aos="zoom-in"
                                data-aos-delay={300}
                            >
                                <div className="feature-box green">
                                    <i className="bi bi-file-earmark-check" />
                                    <h4>Plagiarism Check</h4>
                                    <p>Verify originality using plagiarism detection tools.</p>
                                </div>
                            </div>
                            <div
                                className="col-xl-3 col-md-6"
                                data-aos="zoom-in"
                                data-aos-delay={400}
                            >
                                <div className="feature-box red">
                                    <i className="bi bi-file-text" />
                                    <h4>Formatting</h4>
                                    <p>
                                        Check formatting requirements especially if submitting to a
                                        specific journal.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section id="clients" className="clients section">
                        <div className="container" data-aos="fade-up" data-aos-delay="100">
                            <div className="swiper init-swiper">
                                <script type="application/json" className="swiper-config">
                                    {JSON.stringify({
                                        loop: true,
                                        speed: 600,
                                        autoplay: {
                                            delay: 5000,
                                        },
                                        slidesPerView: "auto",
                                        pagination: {
                                            el: ".swiper-pagination",
                                            type: "bullets",
                                            clickable: true,
                                        },
                                        breakpoints: {
                                            320: {
                                                slidesPerView: 2,
                                                spaceBetween: 40,
                                            },
                                            480: {
                                                slidesPerView: 3,
                                                spaceBetween: 60,
                                            },
                                            640: {
                                                slidesPerView: 4,
                                                spaceBetween: 80,
                                            },
                                            992: {
                                                slidesPerView: 6,
                                                spaceBetween: 120,
                                            },
                                        },
                                    })}
                                </script>
                                <div className="swiper-wrapper align-items-center">
                                    <div className="swiper-slide">
                                        <img src={client1} className="img-fluid" alt="Client 1" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={client2} className="img-fluid" alt="Client 2" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={client3} className="img-fluid" alt="Client 3" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={client4} className="img-fluid" alt="Client 4" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={client5} className="img-fluid" alt="Client 5" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={client6} className="img-fluid" alt="Client 6" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={client7} className="img-fluid" alt="Client 7" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={client8} className="img-fluid" alt="Client 8" />
                                    </div>
                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </section>


                    <section
                        id="testimonials"
                        className="testimonials section light-background"
                    >
                        <div className="container section-title" data-aos="fade-up">
                            <h2>Testimonials</h2>
                            <p>
                                Read feedback from authors and readers to build trust and gain valuable insights into the quality of our services. By sharing their experiences, our clients help us continually improve and refine our platform to better meet the needs of our users.
                            </p>
                        </div>
                        <div className="container">
                            <div className="row g-5">
                                <div
                                    className="col-lg-6"
                                    data-aos="fade-up"
                                    data-aos-delay={100}
                                >
                                    <div className="testimonial-item">
                                        <h3>Dr. Anjali Sharma</h3>
                                        <h4>Prof. &amp; Author</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left" />
                                            <span>
                                                The extensive library of journals and articles on this
                                                platform is remarkable. The variety ensures that every
                                                author finds a perfect fit for their research.
                                            </span>
                                            <i className="bi bi-quote quote-icon-right" />
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-6"
                                    data-aos="fade-up"
                                    data-aos-delay={200}
                                >
                                    <div className="testimonial-item">
                                        <h3>Prof. Rajesh Kumar</h3>
                                        <h4>Professor</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left" />
                                            <span>
                                                Fast processing was the highlight for me! I was amazed
                                                that my paper was published in under two hours without
                                                compromising on quality
                                            </span>
                                            <i className="bi bi-quote quote-icon-right" />
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-6"
                                    data-aos="fade-up"
                                    data-aos-delay={300}
                                >
                                    <div className="testimonial-item">
                                        <h3>Mr. Vivek Rao</h3>
                                        <h4>Author</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left" />
                                            <span>
                                                The reader tools are intuitive and user-friendly.
                                                Searching by topic and downloading in PDF format is
                                                incredibly convenient.
                                            </span>
                                            <i className="bi bi-quote quote-icon-right" />
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-6"
                                    data-aos="fade-up"
                                    data-aos-delay={400}
                                >
                                    <div className="testimonial-item">
                                        <h3>Ms. Priya Iyer</h3>
                                        <h4>Author</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                            <i className="bi bi-star-fill" />
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left" />
                                            <span>
                                                The testimonial section inspired confidence in me before
                                                publishing. After experiencing the service, I can say it
                                                lives up to the positive feedback!
                                            </span>
                                            <i className="bi bi-quote quote-icon-right" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="stats" className="stats section">
                        <div className="container" data-aos="fade-up" data-aos-delay={100}>
                            <div className="row gy-4">
                                <div className="col-lg-3 col-md-6">
                                    <div className="stats-item text-center w-100 h-100">
                                        <span data-count="1502" className="count">1259</span>
                                        <p>Organization</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="stats-item text-center w-100 h-100">
                                        <span data-count="5800" className="count">1565</span>
                                        <p>Journals</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="stats-item text-center w-100 h-100">
                                        <span data-count="1802" className="count">152</span>
                                        <p>Authors</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="stats-item text-center w-100 h-100">
                                        <span className="count">46</span>
                                        <p>Employees</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section id="services" className="services section light-background">
                        <div className="container section-title" data-aos="fade-up">
                            <h2>Services</h2>
                            <p>
                                At College Resource, we provide a comprehensive range of services to help
                                you succeed, from crafting visually stunning websites to managing
                                products and marketing strategies. Our team is dedicated to delivering
                                exceptional quality tailored to your unique needs.
                            </p>
                        </div>
                        <div className="container" data-aos="fade-up" data-aos-delay={100}>
                            <div className="row g-4">
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="service-card d-flex">
                                        <div className="icon flex-shrink-0">
                                            <i className="bi bi-display" />
                                        </div>
                                        <div>
                                            <h3>Web Design</h3>
                                            <p>
                                                Create visually appealing and user-friendly designs that align
                                                with your brand identity and captivate your audience, ensuring a
                                                seamless user experience.
                                            </p>
                                            <a href="service-details.html" className="read-more">
                                                Read More <i className="bi bi-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="service-card d-flex">
                                        <div className="icon flex-shrink-0">
                                            <i className="bi bi-code-slash" />
                                        </div>
                                        <div>
                                            <h3>Web Development</h3>
                                            <p>
                                                Develop robust, scalable, and responsive websites tailored to
                                                your business requirements, using the latest technologies and
                                                frameworks.
                                            </p>
                                            <a href="service-details.html" className="read-more">
                                                Read More <i className="bi bi-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={300}>
                                    <div className="service-card d-flex">
                                        <div className="icon flex-shrink-0">
                                            <i className="bi bi-tools" />
                                        </div>
                                        <div>
                                            <h3>Product Management</h3>
                                            <p>
                                                Manage the lifecycle of your product, from concept to launch, with
                                                a focus on user needs, market trends, and business goals.
                                            </p>
                                            <a href="service-details.html" className="read-more">
                                                Read More <i className="bi bi-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={400}>
                                    <div className="service-card d-flex">
                                        <div className="icon flex-shrink-0">
                                            <i className="bi bi-megaphone" />
                                        </div>
                                        <div>
                                            <h3>Marketing</h3>
                                            <p>
                                                Develop and execute effective marketing strategies to reach your
                                                target audience, enhance brand visibility, and drive business
                                                growth.
                                            </p>
                                            <a href="service-details.html" className="read-more">
                                                Read More <i className="bi bi-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section id="pricing" className="pricing section light-background">
                        <div className="container section-title" data-aos="fade-up">
                            <h2>Pricing</h2>
                            <p>
                                Choose a plan that suits your publication needs and enjoy a
                                seamless publishing experience.
                            </p>
                        </div>
                        <div className="container" data-aos="fade-up" data-aos-delay={100}>
                            <div className="row g-4 justify-content-center">
                                <div
                                    className="col-lg-4"
                                    data-aos="fade-up"
                                    data-aos-delay={100}
                                >
                                    <div className="pricing-card">
                                        <h3>Basic Plan</h3>
                                        <div className="price">
                                            <span className="currency">₹</span>
                                            <span className="amount">1,499</span>
                                            <span className="period">/ publish</span>
                                        </div>
                                        <p className="description">Paper with less than 6 pages</p>
                                        <h4>Featured Included:</h4>
                                        <ul className="features-list">
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Fast
                                                publishing (Under 72 hours)
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Plagiarism
                                                check
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> 24/7 customer
                                                support
                                            </li>
                                        </ul>
                                        <a href="/publish" className="btn btn-primary">
                                            Publish Now
                                            <i className="bi bi-arrow-right" />
                                        </a>
                                    </div>
                                </div>

                                <div
                                    className="col-lg-4"
                                    data-aos="fade-up"
                                    data-aos-delay={200}
                                >
                                    <div className="pricing-card popular">
                                        <div className="popular-badge">Most Popular</div>
                                        <h3>Standard Plan</h3>
                                        <div className="price">
                                            <span className="currency">₹</span>
                                            <span className="amount">1,999</span>
                                            <span className="period">/ publish</span>
                                        </div>
                                        <p className="description">Paper with more than 6 pages</p>
                                        <h4>Featured Included:</h4>
                                        <ul className="features-list">
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Fast
                                                publishing (Under 48 hours)
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Plagiarism
                                                check
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Unlimited
                                                revisions
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Formatting
                                                assistance
                                            </li>
                                        </ul>
                                        <a href="/publish" className="btn btn-light">
                                            Publish Now
                                            <i className="bi bi-arrow-right" />
                                        </a>
                                    </div>
                                </div>

                                <div
                                    className="col-lg-4"
                                    data-aos="fade-up"
                                    data-aos-delay={300}
                                >
                                    <div className="pricing-card">
                                        <h3>Premium Plan</h3>
                                        <div className="price">
                                            <span className="currency">₹</span>
                                            <span className="amount">4,999</span>
                                            <span className="period">/ publish</span>
                                        </div>
                                        <p className="description">Paper with more than 18 pages</p>
                                        <h4>Featured Included:</h4>
                                        <ul className="features-list">
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Fast
                                                publishing (Under 24 hours)
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Plagiarism
                                                check
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Unlimited
                                                revisions
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Priority
                                                support
                                            </li>
                                            <li>
                                                <i className="bi bi-check-circle-fill" /> Data analysis
                                                and abstract writing
                                            </li>
                                        </ul>
                                        <a href="/publish" className="btn btn-primary">
                                            Publish Now
                                            <i className="bi bi-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="faq-9 faq section light-background" id="faq">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5" data-aos="fade-up">
                                    <h2 className="faq-title">
                                        Have a question? Check out the FAQ
                                    </h2>
                                    <p className="faq-description">
                                        It provides clear and helpful information to resolve doubts
                                        or clarify details, ensuring a better understanding for the
                                        audience.
                                    </p>
                                    <div
                                        className="faq-arrow d-none d-lg-block"
                                        data-aos="fade-up"
                                        data-aos-delay={200}
                                    >
                                        <svg
                                            className="faq-arrow"
                                            width={200}
                                            height={211}
                                            viewBox="0 0 200 211"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-7"
                                    data-aos="fade-up"
                                    data-aos-delay={300}
                                >
                                    <div className="faq-container">
                                        <div className="faq-item faq-active">
                                            <h3>How quickly can my paper be published?</h3>
                                            <div className="faq-content">
                                                <p>
                                                    We offer fast processing, ensuring your work is ready
                                                    for publication in under two hours.
                                                </p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right" />
                                        </div>
                                        <div className="faq-item">
                                            <h3>
                                                What types of journals and articles are available on
                                                this platform?
                                            </h3>
                                            <div className="faq-content">
                                                <p>
                                                    Our extensive library features over 100 journals and
                                                    500+ articles, including research papers, reviews, and
                                                    case studies.
                                                </p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right" />
                                        </div>
                                        <div className="faq-item">
                                            <h3>Is this platform open access?</h3>
                                            <div className="faq-content">
                                                <p>
                                                    Yes, we promote free or low-cost publishing to make
                                                    research accessible to a wider audience.
                                                </p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right" />
                                        </div>
                                        <div className="faq-item">
                                            <h3>
                                                Do you provide assistance with article submissions?
                                            </h3>
                                            <div className="faq-content">
                                                <p>
                                                    Yes, we offer services like abstract writing,
                                                    formatting assistance, and data analysis to simplify
                                                    the submission process.
                                                </p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right" />
                                        </div>
                                        <div className="faq-item">
                                            <h3>What topics do you cover in your journals?</h3>
                                            <div className="faq-content">
                                                <p>
                                                    Our platform covers diverse fields, from science and
                                                    technology to social sciences and humanities.
                                                </p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right" />
                                        </div>
                                        <div className="faq-item">
                                            <h3>What tools are available for readers?</h3>
                                            <div className="faq-content">
                                                <p>
                                                    Readers can search and filter by topic, journal name,
                                                    or author and download articles in PDF format.
                                                </p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right" />
                                        </div>
                                        <div className="faq-item">
                                            <h3>How do I get started with my submission?</h3>
                                            <div className="faq-content">
                                                <p>
                                                    Simply create an account, choose a journal, and follow
                                                    our step-by-step submission process.
                                                </p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="contact" className="contact section light-background">
                        <div className="container section-title" data-aos="fade-up">
                            <h2>Contact</h2>
                            <p>
                                Wondering how you can publish your research paper? Get in touch with our support team to learn more about our publishing services and how we can assist you.
                            </p>
                        </div>
                        <div className="container" data-aos="fade-up" data-aos-delay={100}>
                            <div className="row g-4 g-lg-5">
                                <div className="col-lg-5">
                                    <div
                                        className="info-box"
                                        data-aos="fade-up"
                                        data-aos-delay={200}
                                    >
                                        <h3>Contact Info</h3>
                                        <p>
                                            Have questions or need assistance with your publication? Reach out to our dedicated team for fast and reliable support tailored to your needs!

                                        </p>
                                        <div
                                            className="info-item"
                                            data-aos="fade-up"
                                            data-aos-delay={500}
                                        >
                                            <div className="icon-box">
                                                <i className="bi bi-envelope" />
                                            </div>
                                            <div className="content">
                                                <h4>Email Address</h4>
                                                <p>publish@primepublicationhub.com</p>
                                                {/* <p>support@primepublicationhub.com</p> */}
                                            </div>
                                        </div>
                                        <div
                                            className="info-item"
                                            data-aos="fade-up"
                                            data-aos-delay={300}
                                        >
                                            <div className="icon-box">
                                                <i className="bi bi-geo-alt" />
                                            </div>
                                            <div className="content">
                                                <h4>Our Location</h4>
                                                <p>Main Gate Rd, IIT Area,</p>
                                                <p> Mumbai, Maharashtra 400076</p>
                                            </div>
                                        </div>
                                        <div
                                            className="info-item"
                                            data-aos="fade-up"
                                            data-aos-delay={400}
                                        >
                                            <div className="icon-box">
                                                <i class="bi bi-calendar-check"></i>
                                            </div>
                                            <div className="content">
                                                <h4>Working hours</h4>
                                                <p>Monday - Saturday</p>
                                                <p>9 AM - 6 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div
                                        className="contact-form"
                                        data-aos="fade-up"
                                        data-aos-delay={300}
                                    >
                                        <h3>Get In Touch</h3>
                                        <p>
                                            Get in touch with us for a seamless publishing experience, and let our dedicated team guide you through the process.
                                        </p>
                                        <form
                                            action="https://script.google.com/macros/s/AKfycbw5vdc40D4uX1QcofQv8rzltuBpKT8SsT8FH1SgPXgmzq_5XL6y0yFB1XMw2aW8aY8/exec"
                                            method="post"
                                            className="php-email-form"
                                            data-aos="fade-up"
                                            data-aos-delay={200}
                                            name="contactForm"
                                        >
                                            <div className="row gy-4">
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="Your Email"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="subject"
                                                        placeholder="Subject"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        name="message"
                                                        rows={6}
                                                        placeholder="Message"
                                                        required
                                                        defaultValue={""}
                                                    />
                                                </div>
                                                <div className="col-12 text-center">
                                                    <div className="loading">Loading</div>
                                                    <div className="error-message" />
                                                    <div className="sent-message">
                                                        Your message has been sent. Thank you!
                                                    </div>
                                                    <button type="submit" className="btn">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </main>
            <footer id="footer" className="footer">
                {/* <div className="container footer-top">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="index.html" className="logo d-flex align-items-center">
                                <span className="sitename">PPH</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>Main Gate Rd, IIT Area,</p>
                                <p>Mumbai, Maharashtra 400076</p>
                                <p className="mt-3">
                                    <strong>Phone:</strong> <span>+1 5589 55488 55</span>
                                </p>
                                <p>
                                    <strong>Email:</strong> <span>publish@primepublicationhub.com</span>
                                </p>
                            </div>
                            <div className="social-links d-flex mt-4">
                                <a href>
                                    <i className="bi bi-twitter-x" />
                                </a>
                                <a href>
                                    <i className="bi bi-facebook" />
                                </a>
                                <a href>
                                    <i className="bi bi-instagram" />
                                </a>
                                <a href>
                                    <i className="bi bi-linkedin" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="/">About us</a>
                                </li>
                                <li>
                                    <a href="/">Services</a>
                                </li>
                                <li>
                                    <a href="/">Terms of service</a>
                                </li>
                                <li>
                                    <a href="/">Privacy policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li>
                                    <a href="/">Content Quality</a>
                                </li>
                                <li>
                                    <a href="/">Ethical Approval</a>
                                </li>
                                <li>
                                    <a href="/">Plagiarism Check</a>
                                </li>
                                <li>
                                    <a href="/">Formatting</a>
                                </li>
                                <li>
                                    <a href="/">More</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li>
                                    <a href="/">Web Design</a>
                                </li>
                                <li>
                                    <a href="/">Web Development</a>
                                </li>
                                <li>
                                    <a href="/">Product Management</a>
                                </li>
                                <li>
                                    <a href="/">Marketing</a>
                                </li>
                                <li>
                                    <a href="/">Graphic Design</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li>
                                    <a href="/">Software Development</a>
                                </li>
                                <li>
                                    <a href="/">Cybersecurity</a>
                                </li>
                                <li>
                                    <a href="/">DevOps Services</a>
                                </li>
                                <li>
                                    <a href="/">Cloud Solutions</a>
                                </li>
                                <li>
                                    <a href="/">IoT Solutions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                <div className="text-center mb-5">
                    <p>
                        © <span>Copyright</span>{" "}
                        <strong className="px-1 sitename">PPH</strong>{" "}
                        <span>All Rights Reserved</span>| <Link to="/privacyandpolicy">Journal Policy</Link>
                    </p>
                </div>
            </footer>

            <a
                href="/"
                id="scroll-top"
                className="scroll-top d-flex align-items-center justify-content-center"
            >
                <i className="bi bi-arrow-up-short" />
            </a>
        </body>
    );
}

export default Home;
