const Data = [
  {
    "id": 4,
    "authorId": 2412003,
    "issnId": "241-2003",
    "journalTitle": "Computational and Numerical Methods for Combinatorial Geometric Series and its Applications",
    "journalPublisher": "Chinnaraji Annamalai",
    "institution": "Indian Institute of Technology, Kharagpur",
    "website": "www.iitkgp.ac.in",
    "authorOneFname": "Chinnaraji Annamalai",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "journalLink": "computational-and-numerical-methods-for-combinatorial-geometric-series-and-its-applications",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

  }, {
    "id": 17,
    "authorId": 2412016,
    "issnId": "241-2016",
    "journalTitle": "An Exploration Of The Quantum Frontier In Data Analytics And Computational Intelligence",
    "journalPublisher": "Subharun Pal",
    "journalLink": "an-exploration-of-the-quantum-frontier-in-data-analytics-and-computational-intelligence",
    "institution": "Indian Institute of Technology Jammu",
    "website": "www.iitjammu.ac.in",
    "authorOneFname": "Subharun Pal",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

  },
  {
    "id": 25,
    "authorId": 2412024,
    "issnId": "241-2024",
    "journalTitle": "The Role of Quantum Computing in Advancing Cross-disciplinary Informatics",
    "journalPublisher": "Erna Hudianti Pujiarini",
    "journalLink": "the-role-of-quantum-computing-in-advancing-cross-disciplinary-informatics",
    "institution": "Universitas Teknologi Digital Indonesia",
    "website": "",
    "authorOneFname": "Ira Zulfa",
    "authorTwoFname": "Asmadi",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


  },
  {
    "id": 5,
    "authorId": 2412004,
    "issnId": "241-2005",
    "authorOneFname": "Singaraju Jyothi",
    "authorTwoFname": "Bhargavi P",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "journalTitle": "Data Science and Computational Biology",
    "journalPublisher": "Singaraju Jyothi",
    "journalLink": "data-science-and-computational-biology",
    "publicationLanguage": "English",
    "institution": "Sri Padmavati Mahila University, India",
    "website": "www.spmvv.ac.in",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


  },
  {
    "id": 5,
    "authorId": 24120013,
    "issnId": "241-2013",
    "authorOneFname": "Koduri Sai Chaitanya",
    "authorTwoFname": "Kudala Chakardhar Reddy",
    "authorThreeFname": "Kothakota Rajkumar",
    "authorFourFname": "Dr. Sudha Y",
    "authorFiveFname": "",
    "journalTitle": "Enhancing Classroom Productivity and Security: A BYOD Management Framework for Schools Using Web-Based Filtering and Firewall Integration ",
    "journalPublisher": "Tharun CK",
    "journalLink": "body-research-paper",
    "publicationLanguage": "English",
    "institution": "Presidency University",
    "website": null,
    "state": "Karnataka",
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1vPVdkmZIxOHVBjFdxVF507yjGJh3x8EU/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1ZxB41lEXguCwfRIrGz28G2qtFqPmh4pm&export=download",
    "code": "",


  },
  {
    "id": 6,
    "authorId": 2412005,
    "issnId": "241-2006",
    "journalTitle": "Enhancing Future Link Prediction in Quantum Computing Semantic Networks through LLM-Initiated Node Features",
    "journalPublisher": "Gilchan Park",
    "journalLink": "enhancing-future-link-prediction-in-quantum-computing-semantic-networks-through-llm-initiated-node-features",
    "institution": "Brookhaven National Laboratory, Computational Science Initiative Upton",
    "website": "www.bnl.gov",
    "authorOneFname": "Gilchan Park",
    "authorTwoFname": "Paul Baity",
    "authorThreeFname": "Byung-Jun Yoon",
    "authorFourFname": "Adolfy Hoisie ",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


  },

  {
    "id": 7,
    "authorId": 2412006,
    "issnId": "241-2007",
    "journalTitle": "Envisioning a Computing Continuum for Science",
    "journalPublisher": "Manish Parashar",
    "journalLink": "envisioning-a-computing-continuum-for-science",
    "institution": "Scientific Computing and Imaging (SCI) Institute",
    "website": "www.utah.edu",
    "authorOneFname": "Manish Parashar",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

  },
  {
    "id": 8,
    "authorId": 24122011,
    "issnId": "2412-2011",
    "journalTitle": "Intelligent Natural Language Search for R&D Documents",
    "journalPublisher": "AKASH M",
    "journalLink": "Intelligent-Natural-Language-Search-for-R&D-Documents",
    "institution": "Presidency University",
    "website": "https://www.linkedin.com/in/akash-m-61488825b/",
    "authorOneFname": "Akash m",
    "authorTwoFname": "Anudeep ",
    "authorThreeFname": "Govardhan",
    "authorFourFname": "Girish Kumar",
    "authorFiveFname": "Veda Kumar",
    "publicationLanguage": "English",
    "state": "KARNATAKA",
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1ZxB41lEXguCwfRIrGz28G2qtFqPmh4pm/view?usp=drive_link",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1ZxB41lEXguCwfRIrGz28G2qtFqPmh4pm&export=download",
    "code": "https://github.com/Lightning-Akash/R-D-Insights-Navigator",
  },
  {
    "id": 1,
    'authorId': 2412000,
    "issnId": "241-2000",
    "authorOneFname": "Ocotlán Díaz-Parra",
    "authorTwoFname": "Jorge A. Ruiz-Vanoye",
    "authorThreeFname": "Alejandro Fuentes-Penna",
    "authorFourFname": "Ricardo A. Barrera-Cámara",
    "journalTitle": "A brief review of the History of Computational Intelligence, Bio-Inspired Computing and Scientific Computing ",
    "journalLink": "a-brief-review-of-the-history-of-computational-intelligence",
    "journalPublisher": "Ocotlán Díaz-Parra",
    "publicationLanguage": "English",
    "institution": " Editorial Académica Dragón Azteca",
    "website": "www.editada.org",
    "state": 'Mexico',
    "preparedDate": ' Jun 26, 2024 ',
    "openPaper": "imgur.com",
    "code": "",
  }, {
    "id": 2,
    "authorId": 2412001,
    "issnId": "241-2001",
    "authorOneFname": "K. Shan",
    "authorTwoFname": "Huang. J",
    "authorThreeFname": "Salmon. J.",
    "authorFourFname": "",
    "authorFiveFname": "",
    "journalTitle": "Advancements and Future Directions in Molecular Dynamics (MD) Simulations",
    "journalPublisher": "Aline Clementine Beatrice",
    "publicationLanguage": "English",
    "institution": "Kampala International University Uganda",
    "website": "",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

  },
  {
    "id": 3,
    "authorId": 2412002,
    "issnId": "241-2002",
    "authorOneFname": "Parag Mhashilkar",
    "authorTwoFname": "Anthony Tiradani",
    "authorThreeFname": "Burt Holzman",
    "authorFourFname": "Mats Rynge",
    "authorFiveFname": "",
    "journalTitle": "Cloud Bursting with GlideinWMS: Means to satisfy ever increasing computing needs for Scientific Workflows",
    "journalPublisher": "Information Sciences Institute ",
    "publicationLanguage": "English",
    "institution": "University of California at San Diego",
    "website": "www.isi.edu",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


  },
  {
    "id": 4,
    "authorId": 2412003,
    "issnId": "241-2003",
    "journalTitle": "Computational and Numerical Methods for Combinatorial Geometric Series and its Applications",
    "journalPublisher": "Chinnaraji Annamalai",
    "institution": "Indian Institute of Technology, Kharagpur",
    "website": "www.iitkgp.ac.in",
    "authorOneFname": "Chinnaraji Annamalai",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


  },
  {
    "id": 5,
    "authorId": 2412004,
    "issnId": "241-2004",
    "authorOneFname": "Singaraju Jyothi",
    "authorTwoFname": "Bhargavi P",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "journalTitle": "Data Science and Computational Biology",
    "journalPublisher": "Singaraju Jyothi",
    "publicationLanguage": "English",
    "institution": "Sri Padmavati Mahila University, India",
    "website": "www.spmvv.ac.in",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


  },
  {
    "id": 6,
    "authorId": 2412005,
    "issnId": "241-2005",
    "journalTitle": "Enhancing Future Link Prediction in Quantum Computing Semantic Networks through LLM-Initiated Node Features",
    "journalPublisher": "Gilchan Park",
    "institution": "Brookhaven National Laboratory, Computational Science Initiative Upton",
    "website": "www.bnl.gov",
    "authorOneFname": "Gilchan Park",
    "authorTwoFname": "Paul Baity",
    "authorThreeFname": "Byung-Jun Yoon",
    "authorFourFname": "Adolfy Hoisie ",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",


  },
  {
    "id": 7,
    "authorId": 2412006,
    "issnId": "241-2006",
    "journalTitle": "Everywhere & Nowhere: Envisioning a Computing Continuum for Science",
    "journalPublisher": "Manish Parashar",
    "institution": "Scientific Computing and Imaging (SCI) Institute",
    "website": "www.utah.edu",
    "authorOneFname": "Manish Parashar",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

  },
  {
    "id": 8,
    "authorId": 2412007,
    "issnId": "241-2007",
    "journalTitle": "EXPLORATORY RESEARCH OPPORTUNITIES OF COMPUTING IN LIFE SCIENCES",
    "journalPublisher": "Dr.Vignesh Ramamoorthy H ",
    "institution": "Sri Krishna Arts and Science College",
    "website": "www.skasc.ac.in",
    "authorOneFname": "Dr.Vignesh Ramamoorthy H ",
    "authorTwoFname": "Dr.Jeen Marseline K.S",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",

  },
  {
    "id": 9,
    "authorId": 2412008,
    "issnId": "241-2008",
    "journalTitle": "Fractals fractional ai based analyses and applications to complex systems",
    "journalPublisher": "Lebanese American University",
    "institution": "University of Massachusetts Chan Medical School",
    "website": "www.lau.edu.lb",
    "authorOneFname": "Dumitru Baleanu",
    "authorTwoFname": "Majaz Moonis",
    "authorThreeFname": "Yu-Dong Zhang",
    "authorFourFname": "Osvaldo Gervasi",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",
  },
  {
    "id": 10,
    "authorId": 2412009,
    "issnId": "241-2009",
    "journalTitle": "Future for Scientific Computing Using Python Vol. 2, No. 1(2015)",
    "journalPublisher": "Rakesh Kumar ",
    "institution": "International Journal of Engineering Technologies and Management Research",
    "website": "www.ijetmr.com",
    "authorOneFname": "Rakesh Kumar ",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",
  },
  {
    "id": 5,
    "authorId": 24120007,
    "issnId": "2412-0007",
    "authorOneFname": "Sandu Yashwanth",
    "authorTwoFname": "Pepeddineni Harshadeep",
    "authorThreeFname": "Madineni Karthikeyan",
    "authorFourFname": "Malyavantham Gowtham",
    "authorFiveFname": "Dr. Aarif Ahamed S",
    "journalTitle": "Online Inspection of Packed Cases",
    "journalPublisher": "Rakshan Koudagani",
    "journalLink": "online-inspection-of-packed-case",
    "publicationLanguage": "English",
    "institution": "Presidency University",
    "website": null,
    "state": "Karnataka",
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1o-L53Ry_2gZDCalXPzR1J0wlbiVwmrCo/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1o-L53Ry_2gZDCalXPzR1J0wlbiVwmrCo&export=download",
    "code": "",
  },
  {
    "id": 12,
    "authorId": 2412011,
    "issnId": "241-2011",
    "journalTitle": "Impact of Quantum Computing in Quantum Supremacy and Parallel Universes",
    "journalPublisher": "Rabab Khan Rongon",
    "institution": "Department of Computer Science and Engineering School of Engineering and Technology IUBAT",
    "website": "www.iubat.edu",
    "authorOneFname": "Rabab Khan Rongon",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Dhaka',
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",
  },
  {
    "id": 18,
    "authorId": 24120002    ,
    "issnId": "2412-0002",
    "journalTitle": "A Framework for Detecting and Explaining Financial Misinformation Using Fine-Tuned BERT Models ",
    "journalLink": "2412-0002",
    "journalPublisher": "Aneesh Gagan Raj",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Aneesh Gagan Raj",
    "authorTwoFname": "Gowtham R Gowda",
    "authorThreeFname": "Deepak U",
    "authorFourFname": "V Hemanth Kumar",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/16Hlbe9jVJWus5QuRym9KNgtFWPxhsct2/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=16Hlbe9jVJWus5QuRym9KNgtFWPxhsct2&export=download",
    "code": "",
  }, {
    "id": 14,
    "authorId": 2412013,
    "issnId": "241-2013",
    "journalTitle": "Machine Learning Applications to Computational Plasma Physics and Reduced-Order Plasma Modeling",
    "journalPublisher": "Farbod Faraj",
    "institution": "Department of Aeronautics, Imperial College",
    "website": "www.imperial.ac.uk",
    "authorOneFname": "Maryam Reza",
    "authorTwoFname": "Farbod Faraj",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": "London",
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",
  },
  {
    "id": 18,
    "authorId": 24120003    ,
    "issnId": "2412-0003",
    "journalTitle": "High-Performance Network Intrusion Detection Engine Software",
    "journalLink": "2412-0003",
    "journalPublisher": "Adithya R",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Adithya R",
    "authorTwoFname": "Prince Gupta",
    "authorThreeFname": "Urja Savalekar",
    "authorFourFname": "K MD Hizqueel Danish",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1g9v_7zRdHRVTTTbE4c9DPpM2PZrD4uUo/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1g9v_7zRdHRVTTTbE4c9DPpM2PZrD4uUo&export=download",
    "code": "https://github.com/princeo741/High-Performance-Network-Intrusion-Detection-Engine-Software",
  },
  {
    "id": 16,
    "authorId": 2412015,
    "issnId": "241-2015",
    "journalTitle": "Quantum Cohesion: Bridging Discrete Models and Continuous Systems in Computational Physics",
    "journalPublisher": "Douglas C",
    "institution": "",
    "website": "youvan.com",
    "authorOneFname": "Douglas C",
    "authorTwoFname": "",
    "authorThreeFname": "",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": null,
    "preparedDate": 2024,
    "openPaper": "",
    "code": "",
  }, {
  "id": 18,
    "authorId": 24120036,
    "issnId": "2412-0036",
    "journalTitle": "Water Borne Disease Detection",
    "journalLink": "2412-0036",
    "journalPublisher": "Ritu Bhakal",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Bhuma Shruthi",
    "authorTwoFname": "Akankssha",
    "authorThreeFname": "Sai Praneeth L",
    "authorFourFname": "Leelambika KV",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1wZRPuv0kz_gH0Sm6DE0dtfoOGntN_QVd/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1wZRPuv0kz_gH0Sm6DE0dtfoOGntN_QVd&export=download",
    "code": "",
  }, {
    "id": 18,
    "authorId": 24120009,
    "issnId": "2412-0009",
    "journalTitle": "Real-Time Queue Detection and Management System Using YOLO Object Detection",
    "journalLink": "real-time-queue-detection-and-management-system-using-yolo-object-detection",
    "journalPublisher": "Amarnath JL",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Manaswi Patil",
    "authorTwoFname": "Pragnyan Satapathy",
    "authorThreeFname": "Subhasish Mondal",
    "authorFourFname": "Gaurav Prasad",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Los Angeles',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1jmhIY33I0rbEW13ddHqsa5bWnCOw86l8/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1jmhIY33I0rbEW13ddHqsa5bWnCOw86l8&export=download",
    "code": "",
  }, {
   "id": 18,
    "authorId": 24120028,
    "issnId": "2412-0028",
    "journalTitle": "Agrisync: A Real-Time Agricultural Resource and Market Connectivity Platform",
    "journalLink": "2412-0028",
    "journalPublisher": "Ramya S U",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Ramya S U",
    "authorTwoFname": "Adeeb Awez Ahmed",
    "authorThreeFname": "Anas Ahmedul Kabir",
    "authorFourFname": "Mahammad Ziyan Saikalgar",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1AZFtB7V0Sv9-odrm_LAsbl4GlgjWllal/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1AZFtB7V0Sv9-odrm_LAsbl4GlgjWllal&export=download",
    "code": "https://github.com/RAMYASRINI0302/AgriSync.git",
  }, {
   "id": 18,
    "authorId": 24120038,
    "issnId": "2412-0038",
    "journalTitle": "Crowdsourcing Platform for Farmers: A Multilingual, AI-Driven Approach for Crop Analysis, Weather Forecasting, and Soil Recommendations",
    "journalLink": "2412-0038",
    "journalPublisher": "Surya Y V",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Sudha Y",
    "authorTwoFname": "Surya sai prakash Y V",
    "authorThreeFname": "Gautham C N",
    "authorFourFname": "Chandan H",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/14ZyPtf_O6LQkjwPktui1wfFan0PUNRNb/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=14ZyPtf_O6LQkjwPktui1wfFan0PUNRNb&export=download",
    "code": "",
  }, {
    "id": 18,
    "authorId": 24120012,
    "issnId": "2412-0012",
    "journalTitle": " AI-Powered Automation for Customer Support:Revolutionizing Ticket Creation and Management",
    "journalLink": "2412-0012",
    "journalPublisher": "Mohammad Travadi",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Mohammed Faizan",
    "authorTwoFname": "Mubarak",
    "authorThreeFname": "Greeshma Reddy",
    "authorFourFname": " Vignesh G",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1LOGjjxUlae5H52IOtdZN_AerowblqMCM/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1LOGjjxUlae5H52IOtdZN_AerowblqMCM&export=download",
    "code": "",
  }, {
    "id": 18,
    "authorId": 24120025,
    "issnId": "2412-0025",
    "journalTitle": "Medical Emergency Handling",
    "journalLink": "2412-0025",
    "journalPublisher": "Mansoor",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Mansoor Khan",
    "authorTwoFname": "Syed Faisal Ehsan",
    "authorThreeFname": "Syed Shallal Shakeer",
    "authorFourFname": "Mohammed Faizan ur Raheman",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1aJ7oK3MRUn0iqTHGyuvCi88O3VjwDkop/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1aJ7oK3MRUn0iqTHGyuvCi88O3VjwDkop&export=download",
    "code": "",
  }, {
    "id": 18,
    "authorId": 24120034,
    "issnId": "2412-0034",
    "journalTitle": "One Stop Solution for Tourism",
    "journalLink": "2412-0034",
    "journalPublisher": "MS Anuhyaram",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Abhishek B",
    "authorTwoFname": "Aishwarya S",
    "authorThreeFname": "Monish J",
    "authorFourFname": "Sneha S",
    "authorFiveFname": "Dr. M.Swapna",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1Pe1Jj7lOT1Rl2S5w5VhNqJmjpY3XUIow/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1Pe1Jj7lOT1Rl2S5w5VhNqJmjpY3XUIow&export=download",
    "code": "",
  }, {
    "id": 18,
    "authorId": 24120022,
    "issnId": "2412-0022",
    "journalTitle": "Integrated Mobile Application for Emergency Medical Assistance: Real - Time Hospital Proximity and Treatment Facility Tracking Using Google Maps Api ",
    "journalLink": "2412-0022",
    "journalPublisher": "Ramya Hanamanth Sataraddi",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Ramya Hanamanth Sataraddi",
    "authorTwoFname": "Tejeswini L",
    "authorThreeFname": "Deekshitha M",
    "authorFourFname": " Dr. Senthil Kumar",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1dNfolSzbPicM6BYSoD9XJc6RiMCGZ5fi/view?usp=drive_link",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1dNfolSzbPicM6BYSoD9XJc6RiMCGZ5fi&export=download",
    "code": "",
  },
  {
    "id": 20,
    "authorId": 24120027,
    "issnId": "2412-0027",
    "journalTitle": "Daily Update on Construction Site Progress",
    "journalLink": "2412-0027",
    "journalPublisher": "Dr. Saravana Kumar",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Punith Kalyan B",
    "authorTwoFname": "Phanikiran N K",
    "authorThreeFname": "Rohit R",
    "authorFourFname": "Pranav Kumar K",
    "authorFiveFname": "Shreyas S",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1ylbuuX1ykP-iEM9lhZ7_e1P1hHEZ-1Wq/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1ylbuuX1ykP-iEM9lhZ7_e1P1hHEZ-1Wq&export=download",
    "code": "",
  },
  {
    "id": 20,
    "authorId": 24120035,
    "issnId": "2412-0035",
    "journalTitle": "Driver Alertness Detection with Dual Alert System",
    "journalLink": "2412-0035",
    "journalPublisher": "Mohammed Azeem",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Mohammed Azeem A",
    "authorTwoFname": "Ashfaq Ur Rahman H N",
    "authorThreeFname": "Sagar H N",
    "authorFourFname": "",
    "authorFiveFname": "",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1Q57B4YauNx9t4XeZh16UYL7L2GSVLk_y/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1Q57B4YauNx9t4XeZh16UYL7L2GSVLk_y&export=download",
    "code": "",
  },
  {
    "id": 20,
    "authorId": 24120037,
    "issnId": "2412-0037",
    "journalTitle": "Waste Management System Website",
    "journalLink": "2412-0037",
    "journalPublisher": "Arumura",
    "institution": "Presidency University",
    "website": "",
    "authorOneFname": "Arumura Naga Sathya Reddy Varaprasad",
    "authorTwoFname": "Mopuri Sai Sujith Reddy",
    "authorThreeFname": "Sangeetha K",
    "authorFourFname": "Vadde Balaji",
    "authorFiveFname": "Dr. Srinivasan T R",
    "publicationLanguage": "English",
    "state": 'Karnataka',
    "preparedDate": 2024,
    "openPaper": "https://drive.google.com/file/d/1ev_YJKY25HML7R-smN0mByr6j6zZE3Yn/view?usp=sharing",
    "downloadPaper": "https://drive.usercontent.google.com/u/0/uc?id=1ev_YJKY25HML7R-smN0mByr6j6zZE3Yn&export=download",
    "code": "https://github.com/varaprasad6292/https-github.com-varaprasad6292-CSE-G11-CAPSTONE-PROJECT",
  },
  // {
  //   "id": 27,
  //   "author": 2412026,
  //   "journalTitle": "",
  //   "journalPublisher": " ",
  //   "institution": "",
  //   "website": "",
  //   "authorOneFname": " ",
  //   "authorTwoFname": "",
  //   "authorThreeFname": "",
  //   "authorFourFname": "",
  //   "authorFiveFname": "",
  //   "publicationLanguage": "English",
  //   "state": null,
  //   "preparedDate": 2024,
  //   "openPaper": "",
  //   "code": "",
  // }, {
  //   "id": 28,
  //   "authorId": 2412027,
  //   "journalTitle": "",
  //   "journalPublisher": " ",
  //   "institution": "",
  //   "website": "",
  //   "authorOneFname": " ",
  //   "authorTwoFname": "",
  //   "authorThreeFname": "",
  //   "authorFourFname": "",
  //   "authorFiveFname": "",
  //   "publicationLanguage": "English",
  //   "state": null,
  //   "preparedDate": 2024,
  //   "openPaper": "",
  //   "code": "",
  // }, {
  //   "id": 29,
  //   "authorId": 2412028,
  //   "journalTitle": "",
  //   "journalPublisher": " ",
  //   "institution": "",
  //   "website": "",
  //   "authorOneFname": " ",
  //   "authorTwoFname": "",
  //   "authorThreeFname": "",
  //   "authorFourFname": "",
  //   "authorFiveFname": "",
  //   "publicationLanguage": "English",
  //   "state": null,
  //   "preparedDate": 2024,
  //   "openPaper": "",
  //   "code": "",
  // }


]
export default Data;
