import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <div className='PrivacyPolicy'>
                <section className="inner-page" style={{ padding: 0 }}>
                    <div className="container">
                        <h3>Terms and Conditions</h3>
                        <ol>
                            <li>Acceptance of Terms : By accessing or using PrimePublicationHub, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, you may not use our services.</li>
                            <li>Intellectual Property : All content published on PrimePublicationHub  is protected by copyright, trademark, and other intellectual property laws. Users are not allowed to reproduce, distribute, or create derivative works without explicit permission.</li>
                            <li>Submission and Review Process : Authors submitting manuscripts to PrimePublicationHub  agree to follow the submission guidelines and undergo the peer-review process. The editorial team reserves the right to accept, reject, or request revisions to submitted manuscripts.</li>
                            <li>Privacy Policy : We respect your privacy. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.</li>
                            <li>Code of Conduct : All users must adhere to ethical standards when using PrimePublicationHub. This includes but is not limited to avoiding plagiarism, providing accurate information, and respecting the rights of others.</li>
                            <li>User Accounts : Users are responsible for maintaining the confidentiality of their account information and passwords. Unauthorized access or use of another user's account is strictly prohibited.</li>
                            <li>Disclaimer of Warranties : PrimePublicationHub  is provided "as is" without any warranties, expressed or implied. We do not guarantee the accuracy, completeness, or reliability of the content.</li>
                            <li>Limitation of Liability : PrimePublicationHub  and its affiliates will not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our services.</li>
                            <li>Termination : We reserve the right to terminate or suspend access to PrimePublicationHub  at our discretion, without notice, for any conduct that we believe violates these terms or is harmful to other users or us.</li>
                            <li>Changes to Terms and Conditions : PrimePublicationHub  reserves the right to update or modify these terms and conditions at any time. Users are responsible for regularly reviewing the terms.</li>
                            <li>Governing Law : These terms and conditions are governed by and construed in accordance with the laws of Ahmedabad, Gujarat, Jurisdiction.</li>
                            <li>Website name: PrimePublicationHub.com</li>
                            <li>This website is managed and maintained by Akash</li>
                        </ol>
                    </div>
                    <div className="container">
                        <h3>Access, Shipping and Distribution Policy</h3>
                        <ol>
                            <li>Digital Content Delivery : 	All published articles and content on PrimePublicationHub  are digital and will be delivered electronically. Users will have access to the content via the website or downloadable formats.</li>
                            <li>Publication Schedule : PrimePublicationHub  follows a publication schedule as outlined on the website. The schedule may include regular issues, special editions, or other types of content releases. Any changes to the publication schedule will be communicated to users through the website or other appropriate channels.</li>
                            <li>Archiving Policy : Archived content will be available to users based on the website's archiving policy. Users may access archived articles as specified by the archiving schedule and policies established by PrimePublicationHub.</li>
                            <li>User Responsibilities : Users are responsible for ensuring they have the necessary equipment, software, and internet connectivity to access the content on PrimePublicationHub. PrimePublicationHub  is not responsible for any technical issues on the user's end that may prevent access to the content.</li>
                            <li>Copyright and Usage : All content published on PrimePublicationHub  is protected by copyright. Users are expected to adhere to copyright laws and the usage policies outlined by PrimePublicationHub.</li>
                            <li>Notification of Changes : Any changes to the access and distribution policies of PrimePublicationHub  will be communicated to users through the website or other means deemed appropriate by the editorial team.</li>
                            {/* <li>Hardcopy Request : We will send Hardcopy of certificates (Author wise individually), single copy of Confirmation letter and single copy of Published paper to only corresponding author only. Kindly check all the details in softcopy. (i.e. in Published paper, E-Copy of certificate, E-Copy of confirmation letter) And if any of the details is inappropriate then inform to us within 3 days of this mail. Once shipment of hardcopy done then we will not correct it. We will process your hardcopy only after receiving Address details. or considered your provided address details as correct. If provided residential Address is incorrect and shipment of the hardcopy returned then we will NOT proceed further. (If author still wants to hardcopy again then extra charges 300 Rs. Will be applicable) Process of dispatching the hardcopy will be take minimum 1 month to maximum 2 months (only in the case of heavy load) we will dispatch certificate between date 25 to 31 of every month.</li> */}
                        </ol>
                    </div>
                    <div className="container">
                        <h3>Privacy Policy</h3>
                        <ol>
                            <li>Information Collection : When Author submit a manuscript/Research Paper/Abstract of Research Paper to PrimePublicationHub, PrimePublicationHub  collect various information about the Paper and Authors. Information such as Author’s name, Designation, Name of Institute/University, Email address, and Contact Number. This information may be combined with information we obtain from public sources, business partners, or other companies. PrimePublicationHub  also Collect the information regarding your Research paper. i.e. Title of Paper, Authors Details, Area of Research, Abstract , Index Terms , and Research Contents. Once you apply for publication, and sign in to our services, you are not anonymous to us. Security of such information is a high priority for us. PrimePublicationHub  automatically receives and records information on our server logs from the information that your browser sends to us, including your IP address, cookies, and the page you request.</li>
                            <li>Use of Information : The information collected may be used by PrimePublicationHub  for the purpose of operating and improving the PrimePublicationHub  Website, fostering a positive user experience, and delivering services that we offer. We may also use the information we gather to inform you of other Notifications/services available from the PrimePublicationHub  Website or to contact you about your opinion of current services or potential new services that may be offered. E-mail addresses of authors/visitors who place electronic orders/query with us are used for reply purposes only. We also collect e-mail addresses from people who subscribe to our e-mail listserv. If you do not want to receive e-mail from us in the future, please let us know by sending e-mail to us and telling us that you do not want to receive e-mail from the Press. PrimePublicationHub  may use your contact information in order to send you e-mail, postal mail, or other communications regarding updates at the PrimePublicationHub  Website, such as upcoming issue related details, different deadlines dates, upcoming Conference or many future proposed details which may be of interest to you. PrimePublicationHub  will provide the authors published paper details to our indexing partner any time without notice to authors.</li>
                            <li>Information Sharing and Disclosure : PrimePublicationHub  Publication does not rent, sell, or share personal information about authors with other people or non-affiliated companies except to provide products or services you've requested, when we have authors permission, or under the following circumstances: We provide the information to trusted partners who work on behalf of or with PrimePublicationHub  Publication under confidentiality agreements. These companies may use your personal information to help PrimePublicationHub  Publication communicate with you about offers from PrimePublicationHub  Publication and our marketing partners. However, these companies do not have any independent right to share this information. We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of PrimePublicationHub  Publication's terms of use, or as otherwise required by law. We transfer all the information about authors and paper if PrimePublicationHub  Publication is acquired by or merged with another company. PrimePublicationHub  Publication works with vendors, partners, advertisers, and other service providers in different industries and categories of business.</li>
                            <li>Changes to this Privacy Policy : PrimePublicationHub  Publication may update this policy. PrimePublicationHub  will notify you about significant changes in the way we treat personal information by sending a notice to the corresponding author’s email address specified in your information with PrimePublicationHub  or by placing a prominent notice on our site. If you have any questions or suggestions about this Policy, please contact us at: publish@primepublicationhub.com</li>
                        </ol>
                    </div>
                    <div className="container">
                        <h3>Refund Policy</h3>
                        <ol>
                            <li>We are not providing any kind of refund.</li>
                            {/* <li>Refund Eligibility : Publication fees paid by authors are generally non-refundable once the manuscript has been accepted for publication.</li>
                            <li>Exceptional Cases : Refund requests for publication fees may be considered in exceptional cases, such as technical errors in payment processing or other extenuating circumstances. Each request will be reviewed on a case-by-case basis.</li>
                            <li>Refund Processing Time : Refund requests, if approved, will be processed within a 7-8 working days. The processing time may vary based on the payment method used.</li>
                            <li>Changes to the Refund Policy : Any changes to the refund policy will be communicated to users through the website or other means deemed appropriate.</li> */}
                        </ol>
                        <p>By using PrimePublicationHub, you agree to the terms outlined in this refund policy. Last updated Jan 2025.</p>
                    </div>
                </section>
                <Footer />
            </div >

        </>
    )
}

export default PrivacyPolicy
